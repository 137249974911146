import React from "react"

type Props = {
  title: string
  date: string
  briefDescription: string
}
const BriefBlogPost: React.FC<Props> = props => {
  return (
    <div className="bg-white my-4 px-4 py-2 rounded-lg shadow-sm">
      <h2 className="text-2xl font-black text-gray-700 leading-tight mb-1">
        {props.title}
      </h2>
      <p className="text-sm text-gray-700 mb-2">{props.date}</p>
      <p className="text-gray-600">{props.briefDescription}</p>
    </div>
  )
}

export default BriefBlogPost
