import React, { useState } from "react"
import Title from "../../components/Title/index"
import { Link, graphql } from "gatsby"
import BriefBlogPost from "../../components/BriefBlogPost/index"
import SEO from "../../components/Seo"

type Props = {
  pageContext: {
    name: string
    slug: string
  }
  data: {
    allContentfulBlogPost: {
      edges: {
        node: {
          title: string
          date: string
          slug: string
          brief: {
            brief: string
          }
        }
      }[]
    }
    site: {
      siteMetadata: {
        prefixes: {
          posts: string
        }
      }
    }
  }
}

const CategoryLayout: React.FC<Props> = ({ pageContext, data }) => {
  const INTERVAL = 5
  const [loaded, setLoaded] = useState({
    array: data.allContentfulBlogPost.edges.slice(0, INTERVAL),
    iteration: 1,
  })

  function loadMore() {
    setLoaded(prev => ({
      array: data.allContentfulBlogPost.edges.slice(
        0,
        INTERVAL * (prev.iteration + 1)
      ),
      iteration: prev.iteration + 1,
    }))
  }
  return (
    <div className="relative h-full">
      <SEO title={pageContext.name} />
      <Title>{pageContext.name}</Title>
      {loaded.array.length === 0 && (
        <div className="absolute absolute-center w-full">
          <p className="text-center text-sm lg:text-lg font-semibold text-gray-700">
            Aún no tenemos artículos para esta categoría.
            <br></br>
            ¡Vuelve más tarde!
          </p>
        </div>
      )}
      {loaded.array.map((edge, i) => (
        <Link
          key={i}
          to={`/${data.site.siteMetadata.prefixes.posts}/${edge.node.slug}`}
        >
          {
            <BriefBlogPost
              briefDescription={edge.node.brief.brief}
              {...edge.node}
            />
          }
        </Link>
      ))}
      {loaded.array.length < data.allContentfulBlogPost.edges.length && (
        <div className="flex justify-center w-full">
          <button
            className="bg-white text-primary-500 rounded-full shadow-sm px-16 py-2 font-semibold focus:outline-none hover:bg-pink-100"
            onClick={loadMore}
          >
            Cargar Más
          </button>
        </div>
      )}
    </div>
  )
}

export const query = graphql`
  query($slugRegex: String!, $dateFormat: String!) {
    allContentfulBlogPost(
      filter: { tags: { elemMatch: { slug: { regex: $slugRegex } } } }
    ) {
      edges {
        node {
          title
          date(formatString: $dateFormat, locale: "es-ES")
          slug
          brief {
            brief
          }
        }
      }
    }
    site {
      siteMetadata {
        prefixes {
          posts
        }
      }
    }
  }
`
export default CategoryLayout
